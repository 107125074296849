const rightObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.classList.add('right-animation-show');
        }
    })
});

const leftObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.classList.add('left-animation-show');
        }
    })
});

const downObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.classList.add('down-animation-show');
        }
    })
});

const rightAnimations = document.querySelectorAll('.right-animation-hidden');
const leftAnimations = document.querySelectorAll('.left-animation-hidden');
const downAnimations = document.querySelectorAll('.down-animation-hidden');
rightAnimations.forEach((element) => rightObserver.observe(element));
leftAnimations.forEach((element) => leftObserver.observe(element));
downAnimations.forEach((element) => downObserver.observe(element));