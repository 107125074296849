$(document).ready(() => {
    $('#input-phone').mask('(00) 00000-0000');
});

const nameIsValid = (name) => {
    return /^[a-zA-Z]{3,}(?: [a-zA-Z]{1,})+$/gm.test(name);
}

const phoneIsValid = (phone) => {
    return /^\(\d{2}\)\s\d{5}-\d{4}$/.test(phone);
}

const emailIsValid = (email) => {
    return /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(email);
}

const validFields = () => {
    return nameIsValid($('#input-name')[0].value) &&
    phoneIsValid($('#input-phone')[0].value) &&
    emailIsValid($('#input-email')[0].value) &&
    !!$('#input-reason')[0].value.length &&
    !!$('#input-message')[0].value.length
}

const getFormData = () => {
    return {
        to: $('#input-email')[0].value,
        subject: $('#input-reason')[0].value,
        text: `De: ${$('#input-name')[0].value}\nTelefone: ${$('#input-phone')[0].value}\nEmail: ${$('#input-email')[0].value}\nMensagem:\n${$('#input-message')[0].value}`
    }
}

$('#contact-form').on("submit", (e) => {
    e.preventDefault();
    const sitekey = `${$('#sitekey').val()}`;
    
    grecaptcha.ready(() => {
        console.log(sitekey);
        grecaptcha.execute(sitekey, { action: 'submit' }).then((token) => {
            if (validFields()) {
                let formData = getFormData();
                formData.token = token;
                
                $.ajax({
                    url: "/send-mail.php",
                    type: 'POST',
                    contentType: 'application/json',
                    data: JSON.stringify(formData),
                    success: function(data) {
                        console.log("Sucesso:", data);
                        $('#contact-form')[0].reset();
                        $('.contact-form-confirm-send').addClass('show');
                        setTimeout(function () {
                            $('.contact-form-confirm-send').removeClass('show');
                        }, 5000);
                    },
                    error: function(data) {
                        console.log("Erro", data);
                        $('#contact-form')[0].reset();
                        $('.contact-form-error-send').addClass('show');
                        setTimeout(function () {
                            $('.contact-form-error-send').removeClass('show');
                        }, 5000);
                    },
                });
            }
        });
    });
})